/* Estilização do container principal */
.mainDiv {
  display: flex;
  flex-direction: column; /* Para que o header e o formulário fiquem um em cima do outro */
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: linear-gradient(120deg, #6441a5 0%, #2a0845 100%);
  padding: 2rem;
}

/* Estilização do cabeçalho */
.divHeader {
  text-align: center;
  margin-bottom: 2rem; /* Espaçamento entre o cabeçalho e o formulário */
  margin-top: -15rem; /* Espaçamento negativo para que o cabeçalho fique mais próximo do topo */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Logo */
.logo {
  width: 15vw; /* Responsivo ao tamanho da largura da tela */
  height: auto;
  margin-bottom: 1rem; /* Espaçamento abaixo do logo */
}

/* Título do cabeçalho */
.titleHeader {
  margin-top: 0;
  font-size: 2rem; /* Tamanho responsivo */
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.1em;
  margin-top: -2em; /* Espaçamento negativo para que o título fique mais próximo do logo */
}

/* Subtítulo do cabeçalho */
.subtitleHeader {
  font-size: 1.5rem;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.1em;
  margin-top: -1em;
}

.formRegister {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px; /* Limita a largura máxima do form */
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  margin: 0 auto; /* Centraliza horizontalmente */
}

/* Estilização dos inputs */
.input {
  width: 100%; /* Use 100% da largura disponível do form */
  max-width: 400px; /* Limite máximo para evitar inputs muito largos */
  padding: 1rem;
  margin-bottom: 1rem;
  margin-left: 2em;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 1rem;
  text-align: center; /* Centraliza o texto dentro do input */
}

/* Estilização do botão */
.button {
  width: 100%; /* Use 100% da largura disponível do form */
  max-width: 400px; /* Limite máximo */
  margin-left: 3em;
  padding: 1rem;
  background-color: #6441a5;
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center; /* Centraliza o texto dentro do botão */
}

.button:hover {
  background-color: #502e91;
}

.textAlreadyRegistered {
  color: #fff;
  font-size: 0.9rem;
  margin-top: 1rem;
}

.textAlreadyRegistered a {
  color: #fff;
  text-decoration: underline;
}

/* Adaptando o layout para dispositivos menores */
@media (max-width: 768px) {
  .formRegister {
    padding: 1.5rem;
  }

  .titleHeader {
    font-size: 1.8rem;
  }

  .logo {
    width: 20vw;
  }
}

@media (max-width: 480px) {
  .formRegister {
    width: 100%;
    padding: 1rem;
  }

  .input,
  .button {
    font-size: 0.9rem;
  }
}
