* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body,
html {
  font-family: "Roboto", sans-serif;
  background-color: #181818;
  color: #f3f3f3;
  overflow-x: hidden;
}

.homeContainer {
  display: flex;
  height: 100vh;
  padding: 1rem;
  gap: 1.5rem;
}

/* Sidebar */
.sidebar,
.sidebarRight {
  width: 18%;
  padding: 1.5rem;
  background: linear-gradient(180deg, #1e1e1e, #2e2e2e);
  border-radius: 10px;
}

.sidebarLogo {
  display: block;
  width: 60px;
  margin: 0 auto 20px;
}

.sidebarNav ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.sidebarNav ul li a {
  text-decoration: none;
  color: #f3f3f3;
  padding: 10px 15px;
  border-radius: 8px;
  transition: all 0.2s;
}

.sidebarNav ul li a:hover {
  background-color: #3a3a3a;
  color: #1da1f2;
}

.tweetButton {
  background-color: #1da1f2;
  color: white;
  font-weight: bold;
  width: 100%;
  padding: 12px;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.tweetButton:hover {
  background-color: #1a91da;
}

/* Feed */
.feed {
  width: 64%;
  background: #2b2b2b;
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.feedHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterButton {
  background: #444;
  color: #f3f3f3;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.filterButton:hover {
  background: #666;
}

.postBox {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.postInput {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #333;
  border-radius: 8px;
  background: #3a3a3a;
  color: #f3f3f3;
  resize: none;
  height: 90px;
}

.postButton {
  align-self: flex-end;
  background: #1da1f2;
  color: white;
  padding: 8px 20px;
  border-radius: 50px;
  cursor: pointer;
}

.posts .post {
  background: #383838;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Sidebar Right */
.sidebarRight {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.sidebarRight h2 {
  margin-bottom: 10px;
  color: #1da1f2;
}

.trendingList li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #3a3a3a;
  border-radius: 8px;
}

/* Mobile Responsivo */
@media (max-width: 768px) {
  .homeContainer {
    flex-direction: column;
    padding: 0.5rem;
    gap: 1rem;
  }

  .sidebar,
  .sidebarRight,
  .feed {
    width: 100%;
  }
}
